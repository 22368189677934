export const UPDATE_LIST_OF_CHALLENGES = 'UPDATE_LIST_OF_CHALLENGES'
export const SET_CHALLENGES_ERROR = 'SET_CHALLENGES_ERROR'
export const CLEAR_CHALLENGES_ERROR = 'CLEAR_CHALLENGES_ERROR'
export const SET_CURRENT_CHALLENGE = 'SET_CURRENT_CHALLENGE'
export const DELETE_CHALLENGE = 'DELETE_CHALLENGE'
export const SET_CURRENT_CHALLENGE_TASKS = 'SET_CURRENT_CHALLENGE_TASKS'
export const SET_CURRENT_CHALLENGE_TASK_INDEX =
  'SET_CURRENT_CHALLENGE_TASK_INDEX'
export const UPDATE_SELECTED_TASK = 'UPDATE_SELECTED_TASK'
export const UPDATE_CURRENT_CHALLENGE_DATA = 'UPDATE_CURRENT_CHALLENGE_DATA'
export const SET_CHALLENGES_STEP_TYPES = 'SET_CHALLENGES_STEP_TYPES'
export const SET_CHALLENGES_LOCATIONS = 'SET_CHALLENGES_LOCATIONS'
export const UPDATE_CURRENT_STEPS_TO_APPROVE = 'UPDATE_CURRENT_STEPS_TO_APPROVE'
export const SET_CHALLENGE_STEP_SUBMIT_AS_APPROVED =
  'SET_CHALLENGE_STEP_SUBMIT_AS_APPROVED'
export const SET_CHALLENGE_STEP_SUBMITS_AS_APPROVED =
  'SET_CHALLENGE_STEP_SUBMITS_AS_APPROVED'
export const SET_CHALLENGE_STEP_SUBMIT_REJECTION_MESSAGE =
  'SET_CHALLENGE_STEP_SUBMIT_REJECTION_MESSAGE'
export const CLICKED_FROM_SEE_ALL_BUTTON = 'CLICKED_FROM_SEE_ALL_BUTTON'
export const UPDATE_CURRENT_STEPS = 'UPDATE_CURRENT_STEPS'
export const SET_CURRENT_CHALLENGE_SUBMIT_ID = 'SET_CURRENT_CHALLENGE_SUBMIT_ID'
