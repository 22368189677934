import * as actions from '../actionTypes/challengesActionTypes'

let initialState = {
  challenges: [],
  currentChallenge: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CHALLENGES_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actions.CLEAR_CHALLENGES_ERROR:
      return {
        ...state,
        error: null,
      }

    case actions.UPDATE_LIST_OF_CHALLENGES:
      const challenges = [action.challengeList || []]
      return { ...state, challenges }

    case actions.SET_CURRENT_CHALLENGE:
      const currentChallenge = state.challenges[0].find(x => x.ID === action.ID)
      return { ...state, currentChallenge }

    case actions.SET_CHALLENGES_STEP_TYPES:
      return { ...state, stepTypes: action.stepTypes }

    case actions.SET_CHALLENGES_LOCATIONS:
      return { ...state, locations: action.locations }

    case actions.UPDATE_CURRENT_CHALLENGE_DATA:
      return { ...state, currentChallenge: action.challenge }

    case actions.SET_CURRENT_CHALLENGE_TASKS:
      return { ...state, currentChallengeTasks: action.tasks }

    case actions.SET_CURRENT_CHALLENGE_TASK_INDEX:
      return { ...state, currentChallengeTaskIndex: action.index }

    case actions.UPDATE_SELECTED_TASK:
      let newCurrentChallengeTasks = []

      const selectedTasks = state.currentChallengeTasks

      if (
        typeof action.challengeTaskIndex == 'number' &&
        action.challengeTaskIndex < selectedTasks?.length
      ) {
        selectedTasks[action.challengeTaskIndex] = action.challengeTask
        newCurrentChallengeTasks = [...selectedTasks]
      } else {
        if (selectedTasks) {
          newCurrentChallengeTasks = [...selectedTasks, action.challengeTask]
        } else {
          newCurrentChallengeTasks = [action.challengeTask]
        }
      }

      return { ...state, currentChallengeTasks: newCurrentChallengeTasks }

    case actions.DELETE_CHALLENGE:
      const challengeIndex = state.challenges[0].findIndex(
        c => c.Id === action.challengeId
      )
      state.challenges[0].splice(challengeIndex, 1)
      const challenges2 = [...state.challenges]
      return { ...state, challenges: challenges2 }

    case actions.UPDATE_CURRENT_STEPS_TO_APPROVE:
      return {
        ...state,
        stepsToApprove: action.stepsToApprove,
      }

    case actions.UPDATE_CURRENT_STEPS:
      return {
        ...state,
        currentChallengeUserSubmits: action.steps,
      }

    case actions.SET_CURRENT_CHALLENGE_SUBMIT_ID:
      return {
        ...state,
        currentChallengeSubmitId: action.currentChallengeSubmitId,
      }

    case actions.CLICKED_FROM_SEE_ALL_BUTTON:
      return {
        ...state,
        displayedBySeeAllButton: action.displayedBySeeAllButton,
      }

    case actions.SET_CHALLENGE_STEP_SUBMIT_AS_APPROVED:
      const stepsToApprove = state.stepsToApprove?.map(x =>
        x.ID === action.challengeStepSubmitID
          ? { ...x, Approved: true }
          : { ...x }
      )
      const currentChallengeUserSubmits =
        state.currentChallengeUserSubmits?.map(x =>
          x.ID === action.challengeStepSubmitID
            ? { ...x, Approved: true }
            : { ...x }
        )
      return {
        ...state,
        stepsToApprove,
        currentChallengeUserSubmits,
      }

    case actions.SET_CHALLENGE_STEP_SUBMITS_AS_APPROVED:
      let stepsToApprove2
      let currentChallengeUserSubmits2

      if (action.challengeId) {
        stepsToApprove2 = state.stepsToApprove.map(x =>
          x.RejectionMessage || x.ChallengeID !== action.challengeId
            ? { ...x }
            : { ...x, Approved: true }
        )
        currentChallengeUserSubmits2 = state.currentChallengeUserSubmits?.map(
          x =>
            x.RejectionMessage || x.ChallengeID !== action.challengeId
              ? { ...x }
              : { ...x, Approved: true }
        )
      }

      if (action.challengeSubmitsIds) {
        stepsToApprove2 = state.stepsToApprove.map(x =>
          x.RejectionMessage || action.challengeSubmitsIds.indexOf(x.ID) === -1
            ? { ...x }
            : { ...x, Approved: true }
        )
        currentChallengeUserSubmits2 = state.currentChallengeUserSubmits?.map(
          x =>
            x.RejectionMessage ||
            action.challengeSubmitsIds.indexOf(x.ID) === -1
              ? { ...x }
              : { ...x, Approved: true }
        )
      }

      return {
        ...state,
        stepsToApprove: stepsToApprove2,
        currentChallengeUserSubmits: currentChallengeUserSubmits2,
      }

    case actions.SET_CHALLENGE_STEP_SUBMIT_REJECTION_MESSAGE:
      const stepsToApprove3 = state.stepsToApprove.map(x =>
        x.ID === action.challengeStepSubmitID
          ? { ...x, RejectionMessage: action.rejectionMessage }
          : { ...x }
      )
      return {
        ...state,
        stepsToApprove: stepsToApprove3,
      }

    default:
      return state
  }
}
