import './StepToApprove.scss'

import ImageZoom from '@moorecp/react-medium-image-zoom/lib/ImageZoom'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-responsive-modal'

import {
  approveChallengeStepSubmit,
  getUserChallengeSubmits,
  rejectChallengeStepSubmit,
  setCurrentChallengeSubmitId,
} from '../../actions/challengesActions'
import common from '../../common'
import RejectModal from '../DeleteModal/RejectModal'

export default function StepToApprove(props) {
  const stepToApprove = props.stepToApprove
  const handleThirdPageContent = props.handleThirdPageContent
  const isThirdPage = props.isThirdPage

  const dispatch = useDispatch()

  const { currentChallengeSubmitId } = useSelector(state => state.challenges)

  const [rejectStepSubmitModalOpen, setRejectStepSubmitModalOpen] =
    useState(false)

  const approveStepSubmit = () => {
    dispatch(approveChallengeStepSubmit(stepToApprove.ID))
  }

  const rejectStepSubmit = rejectionMessage => {
    dispatch(rejectChallengeStepSubmit(stepToApprove.ID, rejectionMessage))
    setRejectStepSubmitModalOpen(false)
  }

  const showAllSubmits = open => {
    dispatch(setCurrentChallengeSubmitId(stepToApprove.ID))
    dispatch(
      getUserChallengeSubmits(stepToApprove.ChallengeID, stepToApprove.User.ID)
    )
    handleThirdPageContent(
      'UserSubmits',
      stepToApprove.ID !== currentChallengeSubmitId
    )
  }

  return (
    <div className='step-to-approve'>
      <Modal
        open={rejectStepSubmitModalOpen}
        onClose={() => setRejectStepSubmitModalOpen(false)}
        center>
        <RejectModal
          element='submit'
          setOpenModal={setRejectStepSubmitModalOpen}
          proceedReject={rejectStepSubmit}
        />
      </Modal>

      <p id='step-to-approve-created'>
        <span className='left'>
          {moment
            .utc(stepToApprove.Created, 'YYYY-MM-DD HH:mm')
            .local()
            .format('MMMM Do YYYY')}
        </span>
        <span className='right'>
          {moment
            .utc(stepToApprove.Created, 'YYYY-MM-DD HH:mm')
            .local()
            .format('h:mm A')}
        </span>
      </p>

      <div id='step-to-approve-user-info'>
        <img
          className='user-image'
          src={
            stepToApprove.User.ProfilePictureURL
              ? `${common.imagesBaseUrl}/${stepToApprove.User.ProfilePictureURL}`
              : '/images/default-user.png'
          }
          alt='Profile pic'
        />
        <div className='prize-code-user-name-email'>
          <p>
            <b>{stepToApprove.User.FullName}</b>
          </p>
          <p>{stepToApprove.User.Email}</p>
        </div>
      </div>

      <p className='step-submit-title'>
        {stepToApprove.ChallengeStepDescription}
      </p>

      {stepToApprove.Text && (
        <p className='step-with-text'>{stepToApprove.Text}</p>
      )}

      {stepToApprove.ImageURL && (
        <ImageZoom
          image={{
            src: `${common.imagesBaseUrl}/${stepToApprove.ImageURL}`,
            className: 'step-with-image',
          }}
          zoomImage={{
            src: `${common.imagesBaseUrl}/${stepToApprove.ImageURL}`,
          }}
        />
      )}

      {stepToApprove.VideoURL && (
        <video width='100%' preload='auto' controls controlsList='nodownload'>
          <source
            src={`${common.videosBaseUrl}/${stepToApprove.VideoURL}`}
            type='video/mp4'
          />
          <h4>Your browser does not support the video tag.</h4>
        </video>
      )}

      {!isThirdPage && (
        <div className='show-all-submits-btn' onClick={() => showAllSubmits()}>
          All Submits
        </div>
      )}

      {stepToApprove.Approved ? (
        <span className='approved-step-text'>Approved</span>
      ) : stepToApprove.RejectionMessage ? (
        <span className='declined-step-text'>Declined</span>
      ) : (
        <>
          <div className='approve-step-btn' onClick={() => approveStepSubmit()}>
            Approve
          </div>
          <div
            className='decline-step-btn'
            onClick={() => setRejectStepSubmitModalOpen(true)}>
            Decline
          </div>
        </>
      )}
    </div>
  )
}
